import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { capsule, clipboard, eye, foot, gavel, microphone, nurse, paw, spine, stethoscope, stretch, tooth, book, school, chair, institution  } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { ReactChild, ReactFragment, ReactPortal } from "react";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  role?:boolean;
  vertical?: boolean;
  journey?: boolean;
  institution?: boolean;
  immediateGoal?: boolean;
  futureGoal?: boolean;
  step1?: boolean;
  step2?: boolean;
  step3?: boolean;
  step4?: boolean;
  step5?: boolean;
  step6?:boolean;
  // Customizable Area End
}

// Customizable Area Start
export interface Dropdown {
  label: string;
  value: string;
}

export interface Goal {
  id: number;
  goal: string;
}

export interface GoalSection {
  name: string;
  goals: {
    id: number;
    goal: string;
  }[];
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;

  showInfo: boolean;
  roleBtn:boolean;
  verticalBtn: boolean;
  medicalJourneyBtn: boolean;
  institutionBtn: boolean;
  immediateGoalBtn: boolean;
  futureGoalBtn: boolean;
  selectedVertical: string;
  isSelected: boolean;
  selectedRole:any;
  selectedMdJourney: any;
  institutions: any;
  institution: string;
  error: string | null;
  highlightedIndex: number,
  warning: string | null;
  otherInstitutionSelected: boolean;
  otherInstitution: string;
  checkedItems: any;
  futureGoal: any;
  searchQuery:any;
  showCounter: boolean;
  progress: number;
  progressStarted: boolean;
  myRole:any;
  MyImmediateGoalsState:any,
  MedicalJourneyState:any,
  learningVerticals:any;
  instituteList:any;
  otherEntry:string | null;
  name: string;
  verticalStep: boolean;
  roleStep:boolean;
  journeyStep: boolean;
  hasAppendedOther:boolean;
  institutionStep: boolean;
  immediateGoalStep: boolean;
  futureGoalStep: boolean;
  filteredInstitutions: any,
  goalSections: GoalSection[]; 
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserListApiCallId: string = "";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";
  apiInstituteListCallId: string = ""
  customformApiCallId: string = "";
  institutionApiCallId: string = "";
  apiRoleListCallId:string = "";
  apiLearningVerticalCallId:string="";
  apiMedicalJourneyCallId:string="";
  apiMyImmediateGoalsCallId:string="";

  //Properties from config
  textReg = new RegExp(/^[a-zA-Z\s]+$/);
  myRoleHeading=configJSON.myRoleHeading;
  myRoleInfoText=configJSON.myRoleInfoText;
  learningVerticalHeading = configJSON.learningVerticalHeading;
  learningVerticalInfoText = configJSON.learningVerticalInfoText;
  medicalJourneyHeading = configJSON.medicalJourneyHeading;
  medicalJourneyInfoText = configJSON.medicalJourneyInfoText;
  institutionHeading = configJSON.institutionHeading;
  institutionInfoText = configJSON.institutionInfoText;
  otherInstitutionPlaceholder = configJSON.otherInstitutionPlaceholder;
  mandatoryField = configJSON.mandatoryField;
  invalidInput = configJSON.invalidInput;
  immediateGoalsHeading = configJSON.immediateGoalsHeading;
  immediateGoalsInfoText = configJSON.immediateGoalsInfoText;
  futureGoalsHeading = configJSON.futureGoalsHeading;
  futureGoalsInfoText = configJSON.futureGoalsInfoText;
  futureGoalsPlaceholder = configJSON.futureGoalsPlaceholder;
  heading1 = configJSON.heading1;
  heading3 = configJSON.heading3;
  infoTextHeading = configJSON.infoTextHeading;
  infoText = configJSON.infoText;
  btnText = configJSON.btnText;
  loaderInfoText = configJSON.loaderInfoText;
  loaderFact = configJSON.loaderFact;
  
  myRoles=[
    { id: 1, name: "Student", icon: nurse },
    { id: 2, name: "Institution", icon: institution },
    { id: 3, name: "Government Agency", icon: clipboard },
  ];
  learningVerticals = [
    { id: 1, name: "Medicine (MD/DO)", icon: stethoscope },
    { id: 2, name: "Physician Assistant", icon: clipboard },
    { id: 3, name: "Podiatry", icon: foot },
    { id: 4, name: "Nursing", icon: nurse },
    { id: 5, name: "Dentistry", icon: tooth },
    { id: 6, name: "Veterinary", icon: paw },
    { id: 7, name: "Pharmacy", icon: capsule },
    { id: 8, name: "Chiropractic", icon: spine },
    { id: 9, name: "Physical Therapy", icon: stretch },
    { id: 10, name: "Speech Language Pathology(SLP)", icon: microphone },
    { id: 11, name: "Optometry", icon: eye },
    { id: 12, name: "Law", icon: gavel },
  ];
  medicalJournies = [
    { id: 1, name: "Undergraduate", icon: book },
    { id: 2, name: "Graduate Residency / Fellowship", icon: school },
    { id: 3, name: "Active Professional", icon: stethoscope },
    { id: 4, name: "Retired Professional", icon: chair },
  ];
  immediateGoals = [
    {
      id: 1,
      goal: "Pass and perform well on quizzes and exams in my program.",
    },
    {
      id: 2,
      goal: "Parallel my learning in class and on quizzes with my national standardized exams.",
    },
    { id: 3, goal: "Track and improve upon clinical skill development." },
    {
      id: 4,
      goal: "Pick career track and then align with admission requirements to enter advanced training program.",
    },
    { id: 5, goal: "Extracurriculars (volunteering, clubs, activities)" },
    { id: 6, goal: "Grow my network." },
    { id: 7, goal: "Find professional opportunities." },
    {
      id: 8,
      goal: "Improve upon financial literacy and access investment opportunities.",
    },
    { id: 9, goal: "Track and improve upon physical and mental health." },
    { id: 10, goal: "Learn new skills outside of my domain." },
    { id: 11, goal: "Find mentorship opportunities." },
    {
      id: 12,
      goal: "Attend events or participate in forums (physical or virtual)",
    },
    { id: 13, goal: "Get feedback on my academic or professional work." },
    {
      id: 14,
      goal: "Find collaboration opportunities (Academic, Research, Entrepreneurship)",
    },
  ];
  inputRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      showInfo: false,
      otherEntry:"",
      filteredInstitutions: [],
      instituteList:[],
      highlightedIndex: -1,
      searchQuery:"",
      roleBtn:true,
      verticalBtn: true,
      medicalJourneyBtn: true,
      institutionBtn: true,
      immediateGoalBtn: true,
      futureGoalBtn: true,
      selectedVertical: "",
      isSelected: false,
      hasAppendedOther:false,
      selectedRole:"",
      selectedMdJourney: "",
      institutions: [],
      institution: "",
      error: null,
      warning: null,
      otherInstitutionSelected: false,
      otherInstitution: "",
      checkedItems: [],
      futureGoal: "",
      showCounter: false,
      progress: 0,
      progressStarted: false,
      name: "",
      roleStep:false,
      myRole:[],
      MyImmediateGoalsState:"",
      MedicalJourneyState:[],
      learningVerticals:[],
      verticalStep: false,
      journeyStep: false,
      institutionStep: false,
      immediateGoalStep: false,
      futureGoalStep: false,
      goalSections: [],
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      switch (apiRequestCallId) {
        case this.institutionApiCallId:
          this.handleGetInstitutionsRespone(responseJson, errorReponse);
          break;
        case this.customformApiCallId:
          this.handleCreateCustomformResponse(responseJson, errorReponse);
          break;
        case this.apiInstituteListCallId:
          this.getAllInstituteListData(responseJson,errorReponse);
       case this.apiRoleListCallId:
            this.getAllRoleListData(responseJson,errorReponse);
        break;
       case this.apiLearningVerticalCallId:
          this.getAllLearningVerticalData(responseJson,errorReponse);
        break;
        case this.apiMedicalJourneyCallId:
          this.getMedicalJourneyData(responseJson, errorReponse);
        break;
        case this.apiMyImmediateGoalsCallId:
          this.MyImmediateGoalsListData(responseJson,errorReponse)
    
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getAllInstituteListData("", ""); 
    super.componentDidMount();
    await this.getFutureGoalTempData();
    await this.getUsername();
    this.getAllLearningVerticalList();
    this.getMedicalJourneyList();
    this.getMyImmediateGoalsList();
    this.getAllRoleList();
    this.getMedicalJourneyList();
    this.setState({otherInstitutionSelected:false})
    const loaderPage = window.location.pathname;

    if (loaderPage === "/PersonalizationLoader") {
      if (!this.state.progressStarted) {
        this.handleProgress();
      }
    }

    if(loaderPage === '/MyInstitution') {
      this.getInstitutions();
    }
  }

  getInstitutions = async () => {
    let token = await getStorageData('signupToken');

    const header = {
      "Content-Type": configJSON.contentType,
      "token": token,
    };

    const institutions = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.institutionApiCallId = institutions.messageId;
    institutions.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.institutionsEndpoint
    );
    institutions.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    institutions.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(institutions.id, institutions);
  };

  handleGetInstitutionsRespone = (response: any, error: any) => {
    if (response?.data) {
      this.setState({ institutions: response.data });
    } else if (response?.errors) {
      this.setState({ error: configJSON.requestError})
    } else {
      this.setState({ error: error });
    }
  };

  getLearningVerticalTempData = async () => {
    let vertical = await getStorageData("learningVertical");

    if (vertical) {
      this.setState({
        selectedVertical: vertical,
        verticalBtn: false,
      });
    }
  };

  debounce(func: Function, delay: number) {
    let timer: NodeJS.Timeout;
    return function (...args: any[]) {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  }
  
  handleSearchInputDebounced = this.debounce((searchQuery: string) => {
    this.getAllInstituteList(searchQuery); 
    this.getAllInstituteListData("", ""); 
  }, 500);
  
  
  handleSearchInput = (e: any) => {
    let searchQuery = e.target.value;
  
    if (searchQuery.trim() === "(Other)") {
      this.resetSearchState();
      return;
    }
  
    if (searchQuery.includes("(Other)") && !searchQuery.endsWith("(Other)")) {
      searchQuery = searchQuery.replace(" (Other)", "").trim();
      this.setState({ hasAppendedOther: false });
    }
  
    if (searchQuery === "") {
      this.resetSearchState();
      return;
    }
    this.setState({ searchQuery });
  
   
    const enableButton = this.state.filteredInstitutions.length > 0 || searchQuery.includes("(Other)");
    this.setState({ institutionBtn: !enableButton });
  
    this.handleSearchInputDebounced(searchQuery.trim());
    
  };

  //istanbul ignore next 

  setCursorToEnd = (inputElement: HTMLInputElement) => {
    const valueLength = inputElement.value.length;
    inputElement.focus();
    inputElement.setSelectionRange(valueLength, valueLength);
  };
  
  
  resetSearchState = () => {
    this.setState({
      searchQuery: "",           
      instituteList: [],        
      institutionBtn: true,   
      hasAppendedOther: false,
    });
  };

  //istanbul ignore next 
  updateFilteredInstitutions = (searchQuery: string) => {
    const { instituteList, hasAppendedOther } = this.state;

    const invalidPattern = /[@!#$%^&*()_+={}\[\]:;"'<>,.?\/\\|`~]/;

    if (invalidPattern.test(searchQuery)) {

      this.setState({
        filteredInstitutions: [],
        highlightedIndex: -1,
        institutionBtn: true,
      });
        return;
     }

  if (Array.isArray(instituteList)) {
    const filteredInstitutions = instituteList
      .filter((institution: { attributes: { name: string } }) =>
        institution.attributes.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .map((institution: { attributes: { name: string } }) => institution.attributes.name);
    this.setState({
      filteredInstitutions,
      highlightedIndex: -1,
    });
  } else if (instituteList === "Not found any data!") {
    this.setState({filteredInstitutions:[]})
    this.appendOtherToSearchQuery(hasAppendedOther);
  }
  };
//istanbul ignore next 
  appendOtherToSearchQuery = (hasAppendedOther: boolean) => {
    
  const { searchQuery } = this.state;
  const invalidPattern = /[@!#$%^&*()_+={}\[\]:;"'<>,.?\/\\|`~]/;

  if (invalidPattern.test(searchQuery)) {
    return;
  }

  if (!searchQuery.includes(" (Other)") &&  this.state.searchQuery !== "" && !hasAppendedOther ) {
    this.setState((prevState) => ({
      searchQuery: `${prevState.searchQuery.trim()} (Other)`,
      hasAppendedOther: true,
      institutionBtn: false,
    }));
  }
  };

//istanbul ignore next 

  handleKeyDown = (event: {
    currentTarget: any;
    preventDefault(): unknown; key: string;
  }) => {
    const { highlightedIndex, filteredInstitutions, searchQuery } = this.state;
  
    if (event.key === "Backspace" && searchQuery.endsWith(" (Other)") && event.currentTarget.selectionStart === searchQuery.length) {
      event.preventDefault();
      const newQuery = searchQuery.slice(0, -" (Other)".length).trim();
      this.setState({
          searchQuery: newQuery,
          hasAppendedOther: false
      }, () => {
          if (this.inputRef.current) {
              this.inputRef.current.selectionStart = this.inputRef.current.selectionEnd = newQuery.length;
          }
      });
    }
  
    if (event.key === 'ArrowDown') {
    
      this.setState({
        institutionBtn: false,
        highlightedIndex: (highlightedIndex + 1) % filteredInstitutions.length
      });
    } else if (event.key === 'ArrowUp') {
     
      this.setState({
        institutionBtn: false,
        highlightedIndex:
          (highlightedIndex - 1 + filteredInstitutions.length) % filteredInstitutions.length
      });
    } else if (event.key === 'Enter' && highlightedIndex >= 0 && filteredInstitutions.length > 0) {
  
      const selectedItemName = filteredInstitutions[highlightedIndex];
      this.setState({
        searchQuery: selectedItemName,
        filteredInstitutions: [],
        highlightedIndex: -1,
        hasAppendedOther: false,
        institutionBtn: false,
      });
    }
  };
  

    handleSuggestionClick = (institutionName: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined) => {
      this.setState({
        searchQuery: institutionName,   
        filteredInstitutions: [] ,
        highlightedIndex: -1,
      });
    };

  getMedicalJourneyTempData = async () => {
    let medicalJourney = await getStorageData("medicalJourney");

    if (medicalJourney) {
      this.setState({
        selectedMdJourney: medicalJourney,
        medicalJourneyBtn: false,
      });
    }
  };

  getMyRoleTempData = async () => {
    let roleData = await getStorageData("role");

    if (roleData) {
      this.setState({
        selectedRole: roleData,
        roleBtn: false,
      });
    }
  };

  getInstitutionTempData = async () => {
    let institution = await getStorageData("institution");

    if (institution) {
      const {
        institutionTemp,
        otherInstitutionTemp,
        otherInstitutionSelectedTemp,
      } = JSON.parse(institution);

      this.setState({
        institution: institutionTemp,
        otherInstitution: otherInstitutionTemp,
        otherInstitutionSelected: otherInstitutionSelectedTemp,
      });
    }
  };

  getImmediateGoalTempData = async () => {
    let immediateGoals = await getStorageData("immediateGoal");
    let goals = JSON.parse(immediateGoals);
    if (goals) {
      this.setState({
        checkedItems: goals,
      });
    }
  };

  generateGoalSections(apiData: any = this.state.MyImmediateGoalsState) {
    if (!apiData) {
      console.log("No API data available.");
      return [];
    }

    return [
      {
        name: "Educational",
        goals: apiData.Educational.map((goal: string, index: number) => ({
          id: `edu-${index}`, 
          goal,
        })),
      },
      {
        name: "Professional Development and Research",
        goals: apiData.ProfessionalDevelopmentAndResearch.map((goal: string, index: number) => ({
          id: `pro-${index}`,
          goal,
        })),
      },
      {
        name: "Personal",
        goals: apiData.Personal.map((goal: string, index: number) => ({
          id: `per-${index}`, 
          goal,
        })),
      },
    ];
}

  getUsername = async () => {
    const nameData = await getStorageData("signupData");

    if (nameData) {
      const name = JSON.parse(nameData);
      const firstname = name.firstnameTemp;
      this.setState({ name: firstname });
    } else {
      this.setState({
        name: "Joshua",
      });
    }
  };

  getFutureGoalTempData = async () => {
    let futureGoal = await getStorageData("futureGoal");

    if (futureGoal) {
      this.setState({
        futureGoal,
        futureGoalBtn: false,
      });
    }
  };


  handleIconHover = () => {
    this.setState({ showInfo: true });
  };

  handleIconLeave = () => {
    this.setState({ showInfo: false });
  };

  goBack = () => {
    this.props.navigation.goBack();
  };

  handleVerticalChange = (event: any) => {
    let selectedVertical = event.target.value;
    this.setState({ selectedVertical, verticalBtn: false });
  };

  handleLearningVertical = async () => {
    const vertical = this.state.selectedVertical;
    this.setState({verticalStep: true, selectedVertical: vertical});
    
    await setStorageData("learningVertical", vertical);

    this.props.navigation.navigate("MedicalJourney");
  };

  medicalJourneyToApiAttribute: { [key: string]: string } = {
    'Undergraduate': 'undergraduate',
    'Graduate Residency / Fellowship': 'graduate',
    'Active Professional': 'active_professional',
    'Retired Professional': 'retired_professional'
  };  

  handleMdJourneyChange = (event: any) => {
    const selectedJourneyName = event.target.value; 
    this.setState({ selectedMdJourney:selectedJourneyName, medicalJourneyBtn: false } )
    
  };

  handleMdJourney = async () => {
    const medicalJourney = this.state.selectedMdJourney;
    this.setState({journeyStep: true, selectedMdJourney: medicalJourney});

    await setStorageData("medicalJourney", medicalJourney);

    this.props.navigation.navigate("MyInstitution");
  };

  roleToApiAttribute: { [key: string]: string } = {
    Student: 'student',
    Institution: 'institution',
    'Government Agency': 'government_agency'
  };
  handleMyRole = (event:any) => {
    const selectedRoleName = event.target.value; 
    this.setState({ selectedRole:selectedRoleName, roleBtn: false });
  }
  handleRoleClick = async () => {
    const role = this.state.selectedRole;
    this.setState({roleStep: true, selectedRole: role});

    await setStorageData("role", role);

    this.props.navigation.navigate("LearningVertical");
  };
  

  handleInstitutionChange = async (event: any) => {
    const institution = event.target.value;

    this.setState({
      institution,
      error: null,
      warning: null,
      otherInstitutionSelected: institution === "0",
    }, () => {
      this.saveInstitutionCache();
      this.handleInstitutionBtn();
    });
  };

  handleOtherInstitutionChange = (event: any) => {
    const otherInstitution = event.target.value;
    
    this.setState({ otherInstitution, error: null, warning: null }, () => {
      this.saveInstitutionCache();
      this.handleInstitutionBtn();
    });
  };
 
  handleInstitutionNext = async () => {
    this.saveInstitutionCache();
   
    this.setState({ institutionStep: true })

    this.props.navigation.navigate("MyImmediateGoals");
  };

  handleInstitutionBtn = () => {
    const { otherInstitution } = this.state;

    if (otherInstitution.trim() === "") {
      this.setState({ institutionBtn: true });
    } else {
      this.setState({ institutionBtn: false });
    }
  }

  async saveInstitutionCache(){

    let searchQuery = this.state.searchQuery;
        if (searchQuery.includes("(Other)")) {
            searchQuery = searchQuery.replace("(Other)", "").trim();
        }
    
    const institution = {
      institutionTemp: searchQuery,
      otherInstitutionTemp: this.state.otherInstitution,
      otherInstitutionSelectedTemp: this.state.otherInstitutionSelected,
    };

    await setStorageData("institution", JSON.stringify(institution));
  }

  handleGoalChange = async (event:any) => {
    const goalId = event.target.name; 
    const goalText = event.target.value; 
    const isChecked = event.target.checked;
    this.setState(prevState => {
        const existingGoals = [...prevState.checkedItems];
        let updatedGoals;
        if (isChecked) {
          if (!existingGoals.find((item) => item.id === goalId)) {
            updatedGoals = [...existingGoals, { id: goalId, goal: goalText }];
          } else {
            updatedGoals = existingGoals;
          }
        } else {
          updatedGoals = existingGoals.filter((item) => item.id !== goalId);
        }

        setStorageData("immediateGoal", JSON.stringify(updatedGoals.map((item: { goal: any; }) => item.goal)));

        return {
            checkedItems: updatedGoals,
            immediateGoalBtn: updatedGoals.length === 0,
        };
    });
  };

  handleImmediateGoal = async () => {
    const immediateGoal = this.state.checkedItems;
    this.setState({immediateGoalStep: true})
    
    await setStorageData("immediateGoal", JSON.stringify(immediateGoal));

    this.props.navigation.navigate("MyFutureGoals");
  };

  handleFutureGoalChange = (event: any) => {
    let futureGoal = event.target.value;
    this.setState(
      {
        futureGoal,
        futureGoalBtn: false,
        showCounter: true,
        error: null,
        warning: null,
      },
      () => {
        if (futureGoal === "")
          this.setState({ showCounter: false, futureGoalBtn: true });
      }
    );
  };

  handleFutureGoal = async () => {
    let futureGoal = this.state.futureGoal.trim();
    this.setState({ futureGoal, futureGoalStep: true });
    
    await setStorageData("futureGoal", this.state.futureGoal);
    
    this.createCustomform();
  };

  getAllRoleListData = (response:any, error:any)=>{
    this.setState({myRole:response})
  }
  getAllLearningVerticalData = (response:any, error:any)=>{
    this.setState({learningVerticals:response})
  }
  getMedicalJourneyData = (response:any, error:any)=>{
    this.setState({MedicalJourneyState:response})
  }
  MyImmediateGoalsListData = (response:any, error:any)=>{
    this.setState({MyImmediateGoalsState:response},()=>{
      const goalSections = this.generateGoalSections(response);
    this.setState({ goalSections });
    })
  }
  getAllLearningVerticalList = async () => {
    this.apiLearningVerticalCallId = await this.apiCall({
      contentType: "application/json",
      method: configJSON.instituteListMethod,
      endPoint: configJSON.learningVerticalApiEndPoint
    });
  };
  getAllInstituteList = async (InstituteWord:string) => {
    const endpointWithParams = `${configJSON.instituteListAPiEndPoint}?name=${InstituteWord}`;
    this.apiInstituteListCallId = await this.apiCall({
      contentType: "application/json",
      method: configJSON.instituteListMethod,
      endPoint: endpointWithParams
    });
    setTimeout(()=>{
      this.updateFilteredInstitutions(InstituteWord);
    },500)
  };

  getAllRoleList = async () => {
    this.apiRoleListCallId = await this.apiCall({
      contentType: "application/json",
      method: configJSON.instituteListMethod,
      endPoint: configJSON.myRoleApiEndPoint
    });
  };

  getMedicalJourneyList = async()=>{
    this.apiMedicalJourneyCallId = await this.apiCall({
      contentType: "application/json",
      method: configJSON.instituteListMethod,
      endPoint: configJSON.MedicalJourneyApiEndPoint
    })
  }

  getMyImmediateGoalsList = async()=>{
    this.apiMyImmediateGoalsCallId = await this.apiCall({
      contentType: "application/json",
      method: configJSON.instituteListMethod,
      endPoint: configJSON.MyImmediateGoalsApiEndPoint
    })
  }

  createCustomform = async () => {
    let token = await getStorageData('signupToken');
    await this.getInstitutionTempData();
    await this.getLearningVerticalTempData();
    await this.getMedicalJourneyTempData();
    await this.getImmediateGoalTempData();
    await this.getMyRoleTempData()
    
    
    const formdata = new FormData();
    formdata.append("form[vertical]", this.state.selectedVertical);
  
    formdata.append("form[present_stage]", this.state.selectedMdJourney);
    formdata.append("form[future_goals]", this.state.futureGoal);
    formdata.append("form[role]", this.state.selectedRole)

    formdata.append("form[institute_name]", this.state.institution)
    
    this.state.checkedItems.forEach((value: any) => {
      formdata.append("form[immediate_goals][]", value.goal);
    });

    const header = {
      token
    };

    const customform = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.customformApiCallId = customform.messageId;

    customform.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customformEndpoint}`
    );

    customform.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    customform.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    
    customform.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(customform.id, customform);
  }

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    let token = await getStorageData('signupToken');
    const header = {
      "Content-Type": contentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;

  };

  getAllInstituteListData = (response:any, error:any)=>{

    this.setState({instituteList:response.data || response.message})
  }

  handleCreateCustomformResponse = (response: any, error: any) => {
    if (response?.data) {
      this.props.navigation.navigate("PersonalizationComplete");
    } else if (response?.errors){
      this.setState({warning: response.errors})
    } else {
      this.setState({warning: 'An error occurred, please try again'})
    }
  };

  goToDashboard = () => {
    this.props.navigation.navigate("PersonalizationLoader");
  };

  handleProgress = () => {

    const loaderPage = window.location.pathname;

    if (loaderPage !== "/PersonalizationLoader")  return;

    this.setState({ progressStarted: true });

    const progressInterval = setInterval(() => {
      if (this.state.progress < 100) {
        this.setState(
          (prevState) => ({ progress: prevState.progress + 5 }),
          () => {
            if (this.state.progress == 100) {
              clearInterval(progressInterval);
              this.props.navigation.navigate("Catalogue1");
            }
          }
        );
      }
    }, 200);
  };
  // Customizable Area End
}
